import {
  tableController,
  tablePageController,
  actionCard, 
  openModal,
} from 'magner';
import { t } from './ru';
import orderFilters from './filters';
import type { OrderList } from './types';
import { read } from './requests';
import { formatTime, formatDate } from '~/utils/date-transform';
import WalkingStatusTagTable from './components/walking-status-tag-table.vue';
import TextComponent from './components/walker-table-cmp.vue';
import exportWalker from './export-walker';
import exportWalking from './export-walking';

export const tableConfig = tablePageController<OrderList>({
  header: {
    title: t('table.title'),
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: 'Экспорт выполненных заказов',
          class: 'width-auto',
        },
        action: actionCard(async () => {
          try {
            await openModal({
              type: 'card',
              config: exportWalking,
              customClass: 'modal-base',
              props: {
                emptyCard: true,
                entityId: null,
              },
            });

            return false;
          }
          catch (e) {
            return false;
          }
        }),
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: 'Экспорт выгульщиков',
          class: 'width-auto',
        },
        action: actionCard(async () => {
          try {
            await openModal({
              type: 'card',
              config: exportWalker,
              customClass: 'modal-base',
              props: {
                emptyCard: true,
                entityId: null,
              },
            });

            return false;
          }
          catch (e) {
            return false;
          }
        }),
      },
    ],
  },
  request: read,
  table: tableController<OrderList>({
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: t('table.not_found'),
    columns: [
      {
        prop: 'publicId',
        label: t('table.headers.id'),
        view: {
          type: 'text',
        },
      },
      {
        prop: 'externalId',
        label: t('table.headers.externalId'),
        view: {
          type: 'text',
          formatter: (value) => value as string || '-',
        },
      },
      {
        prop: 'status',
        label: t('table.headers.status'),
        sortable: true,
        view: {
          type: 'custom',
          component: () => WalkingStatusTagTable,
        },
      },
      {
        prop: 'isPaid',
        label: t('table.headers.paymentState'),
        sortable: true,
        view: {
          type: 'text',
          formatter: (cellValue) => (cellValue ? 'Оплачено' : 'Нет оплаты'),
        },
      },
      {
        prop: 'asap',
        label: t('table.headers.asap'),
        sortable: true,
        view: {
          type: 'text',
          formatter: (cellValue) => (cellValue ? 'Да' : '-'),
        },
      },
      {
        prop: 'createdAt',
        label: t('table.headers.createdAt'),
        sortable: true,
        view: {
          type: 'text',
          formatter: (_, row: OrderList) => formatDate(row.createdAt),
        },
      },
      {
        prop: 'startTime',
        label: t('table.headers.walkDate'),
        sortable: true,
        view: {
          type: 'text',
          formatter: (_, row: OrderList) => {
            const startDateTime = new Date(row.startTime);

            // if (row.city.minutesOffset) {
            //   startDateTime.setMinutes(
            //     startDateTime.getMinutes() + row.city.minutesOffset,
            //   );
            // }

            return formatDate(startDateTime);
          },
        },
      },
      {
        prop: 'walkTime',
        label: t('table.headers.walkTime'),
        view: {
          type: 'text',
          formatter: (_, row: OrderList) => {
            const startDateTime = new Date(row.startTime);

            // if (row.city.minutesOffset) {
            //   startDateTime.setMinutes(
            //     startDateTime.getMinutes() + row.city.minutesOffset,
            //   );
            // }

            return formatTime(startDateTime);
          },
        },
      },
      {
        prop: 'walkCity',
        label: t('table.headers.walkCity'),
        sortable: true,
        view: {
          type: 'text',
          formatter: (_, row: OrderList) => row.city.name,
        },
      },
      {
        prop: 'walkDistrict',
        label: t('table.headers.walkDistrict'),
        sortable: true,
        view: {
          type: 'text',
          formatter: (_, row: OrderList) => row.district?.slug,
        },
      },
      {
        prop: 'client',
        label: t('table.headers.client'),
        sortable: true,
        showOverflowTooltip: true,
        view: {
          type: 'text',
          formatter: (_, row: OrderList) => `${row.user?.name || '-'}, ${row.user?.phone || '-'}`,
        },
      },
      {
        prop: 'price',
        label: t('table.headers.totalCost'),
        sortable: true,
        view: {
          type: 'text',
          formatter: (_, row: OrderList) => `${row.price} руб.`,
        },
      },
      {
        prop: 'petBreeds',
        label: t('table.headers.petBreeds'),
        showOverflowTooltip: true,
        view: {
          type: 'text',
          formatter: (_, row: OrderList) => (row.pets 
            ? [...new Set(row.pets.map((a) => a.breed || 'Без породы'))].join(', ')
            : ''),
        },
      },
      {
        prop: 'petAges',
        label: t('table.headers.petAge'),
        view: {
          type: 'text',
        },
      },
      {
        prop: 'weight',
        label: t('table.headers.weight'),
        view: {
          type: 'text',
          formatter: (_, row: OrderList) => row.pets.map((a) => `${a.weight} кг.`).join(', '),
        },
      },
      {
        prop: 'walker',
        label: t('table.headers.walker'),
        showOverflowTooltip: true,
        view: {
          type: 'custom',
          component: () => TextComponent,
        },
      },
      {
        prop: 'rating',
        label: t('table.headers.mark'),
        sortable: true,
        view: {
          type: 'text',
          formatter: (value) => value as string || '-',
        },
      },
      {
        prop: 'features',
        label: t('table.headers.markDescription'),
        view: {
          type: 'text',
          formatter: (_, row) => (row.features?.length
            ? row.features.map((item) => item.title).join(', ')
            : '-'),
        },
      },
      {
        prop: 'orderSource',
        label: t('table.headers.orderSource'),
        view: {
          type: 'text',
          formatter: (_, row) => row.source.label,
        },
      },
    ],
    rowLink: (row) => ({
      name: row.isEditing ? 'order-edit' : 'order',
      params: { id: row.id },
    }),
  }),
  filters: orderFilters as any,
});
