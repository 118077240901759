import { validationController } from 'magner';

/** Validations are functions that are used for form fields validation.
 * Here, you are configuring the object where key is a validation unique name and value is
 * a checking function.<br>
 *
 * First argument is a data you can use to check field value<br>
 * Second argument is callback. If callback argument is 'new Error("message")', the error will be shown over the field.
 */

const scrollToError = () => {
  const error = document.querySelector('.is-error');

  if (!error) {
    return;
  }

  error.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

const callScrollToError = () => {
  // setTimeout(scrollToError, 250);
};

const validation = validationController({
  'set-dirty-form': ({ form }, callback) => {
    form.isDistrictStreetDirty = 1;
    callback();
  },

  'empty-required': ({ value, rule, form }, callback) => {
    if (
      typeof value === 'undefined'
      || value === null
      || (typeof value === 'string' && !value?.trim().length)
      || (Array.isArray(value) && !value.length)
      || (typeof value === 'object'
        && !(value instanceof Date)
        && Object.keys(value).length === 0)
    ) {
      if (rule.field === 'district' || rule.field === 'street') {
        if (form.isDistrictStreetDirty === 1) {
          callback(new Error('Поле обязательно к заполнению'));
        }
      }
      else {
        callback(new Error('Поле обязательно к заполнению'));
        callScrollToError();
      }
    }
    callback();
  },

  'empty-number-required': ({ value, form }, callback) => {
    if (!value || String(value)?.trim()?.length) {
      callback(new Error('Поле обязательно к заполнению'));

      callScrollToError();
    }
    callback();
  },

  'empty-required-array': ({ value, form }, callback) => {
    if (
      typeof value === 'undefined'
      || value === null
      || (typeof value === 'string' && !value?.trim().length)
      || (Array.isArray(value) && !value.length)
    ) {
      callback(new Error('Поле обязательно к заполнению'));

      callScrollToError();
    }
    callback();
  },

  'empty-required-user': ({ value, form }, callback) => {
    if (typeof value === 'undefined' || value === null) {
      callback(new Error('Поля обязательны к заполнению'));
      return;
    }

    if (value.type === 'new') {
      Object.values(value.newEntity).map((item) => {
        if (
          typeof item === 'undefined'
          || item === null
          || (typeof item === 'string' && !item?.trim().length)
          || (Array.isArray(item) && !item.length)
        ) {
          callback(new Error('Поля обязательны к заполнению'));

          callScrollToError();
        }

        return item;
      });
    }
    else if (!value.existingId || value.existingId.length === 0) {
      callback(new Error('Поля обязательны к заполнению'));

      callScrollToError();
    }

    callback();
  },

  'empty-required-pet': ({ value, form }, callback) => {
    if (typeof value === 'undefined' || value === null) {
      callback(new Error('Поля обязательны к заполнению'));

      callScrollToError();
      return;
    }

    if (value.type === 'new') {
      const requireInputs = ['name', 'birthdate'];

      if (value.newEntity.petType === 'dog' || value.newEntity.petType === 'cat') {
        requireInputs.push('breedId');
      }

      if (value.newEntity.petType === 'dog' || value.newEntity.petType === 'cat' || value.newEntity.petType === 'other') {
        requireInputs.push('weight', 'sex');
      }

      Object.entries(value.newEntity).map((item) => {
        if (requireInputs.includes(item[0]) && !item[1]) {
          callback(new Error('Поля обязательны к заполнению'));

          callScrollToError();
        }

        return item;
      });
    }
    else if (!value.existingId || value.existingId.length === 0) {
      callback(new Error('Поля обязательны к заполнению'));

      callScrollToError();
    }

    callback();
  },

  'empty-required-pet-age': ({ value, form }, callback) => {
    if (typeof value === 'undefined' || value === null || value === '') {
      callback(new Error('Поля обязательны к заполнению'));

      callScrollToError();
      return;
    }

    if (value.min < 0 || value.max < value.min) {
      callback(new Error('Некорректно заполнено поле'));

      callScrollToError();
      return;
    }

    callback();
  },

  'empty-required-discounts-object': ({ value, form }, callback) => {
    if (typeof value === 'undefined' || value === null || !value) {
      callback(new Error('Поля обязательны к заполнению'));

      callScrollToError();
      return;
    }

    if (value.isAll) {
      callback();

      return;
    }

    if (!value.offer) {
      callback(new Error('Поля обязательны к заполнению'));

      callScrollToError();

      return;
    }

    if (!value.isAllOffer && value.type === 'tariff' && !value.tariff.length) {
      callback(new Error('Поля обязательны к заполнению'));

      callScrollToError();

      return;
    } 

    if (!value.isAllOffer && value.type === 'option' && !value.option.length) {
      callback(new Error('Поля обязательны к заполнению'));

      callScrollToError();

      return;
    } 

    callback();
  },

  'empty-required-discounts-transition': ({ value }, callback) => {
    if (typeof value === 'undefined' || value === null || !value) {
      callback(new Error('Поля обязательны к заполнению'));

      callScrollToError();
      return;
    }

    if (value.isLink && (!value.linkText || !value.price)) {
      callback(new Error('Поля обязательны к заполнению'));

      callScrollToError();

      return;
    }

    if (!value.isLink && (!value.offer || !value.tariff || (!value.isNoOption && !value.option))) {
      callback(new Error('Поля обязательны к заполнению'));

      callScrollToError();

      return;
    }

    callback();
  },

  'email-required': ({ rule, value }, callback) => {
    if (value && !value.match(/[^@]+@[^.]+\..+/i)) {
      callback(new Error('Неверный формат E-mail'));

      callScrollToError();
    }
    else {
      callback();
    }
  },

  url: ({ rule, value }, callback) => {
    if (
      value
      && !value.match(/(https?:\/\/)[a-zа-яё0-9._-]+\.[a-zа-яё]+(\/?.*)?/i)
    ) {
      callback(new Error('Неверный формат ссылки'));

      callScrollToError();
    }
    else {
      callback();
    }
  },

  /** Example validator showing that you can access values from other fields of the form */
  passwordConfirm: ({ value, form }, callback) => {
    if (value && form.password !== value) {
      callback(new Error('Пароли не совпадают!'));

      callScrollToError();
    }
    else if (!value) {
      callback(new Error('Введите повтор пароля'));

      callScrollToError();
    }
    else {
      callback();
    }
  },

  /** Phone field validation */
  'number-only': ({ value }, callback) => {
    if (/[^0-9.]/.test(value)) {
      callback(new Error('Значение может состоять только из цифр и точки'));

      callScrollToError();
    }
    else {
      callback();
    }
  },

  'number-abs': ({ value }, callback) => {
    if (+value <= 0) {
      callback(new Error('Значение должно быть больше 0'));

      callScrollToError();
    }
    
    else {
      callback();
    }
  },

  'is-link': ({ value }, callback) => {
    if (value && !/(https?:\/\/[^\s]+)/g.test(value)) {
      callback(new Error('Некорректная ссылка'));

      callScrollToError();
    }
    else {
      callback();
    }
  },

  'birthday-adult': ({ value }, callback) => {
    const date = new Date(value);
    const currentDate = new Date();

    const age = Math.abs(
      new Date(currentDate.getTime() - date.getTime()).getUTCFullYear() - 1970,
    );

    if (currentDate.getTime() < date.getTime()) {
      callback(new Error('Некорректная дата рождения'));

      callScrollToError();
    }
    else if (age < 18) {
      callback(new Error('Не младше 18 лет'));

      callScrollToError();
    }
    else {
      callback();
    }
  },

  'walk-services': ({ value }, callback) => {
    if (!value.type || !value.type.length) {
      value.typeError = 'Поле обязательно к заполнению';
    }

    // if (!value.offerType || !value.offerType.length) {
    //   value.offerTypeError = 'Поле обязательно к заполнению';
    // }

    callback();
  },
});

export default validation;
